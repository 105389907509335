@tailwind base;
@tailwind components;
@tailwind utilities;

/* Directly targeting checkbox input for scaling */
@layer components {
  input[type='checkbox'] {
    @apply transform; /* Use Tailwind utility to ensure consistency */
    scale: 1.2; /* Scale up checkbox */
  }

  /* Custom styling for number input to remove arrows */
  input[type='number'] {
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Modern browsers */
    margin: 0; /* Ensures consistent styling */
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .container-fluid {
    padding: 10px 1rem;
  }
  .input-field {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    color: #000000;
    font-size: 14px;
    border-radius: 10px;
    padding: 0.85rem 2.5rem;
    width: 100%;
    transition:
      border-color 0.2s ease,
      box-shadow 0.2s ease; /* Transition for focus effect */
  }

  .input-field:focus {
    border-color: #3b82f6; /* Tailwind's border-blue-500 */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2); /* Focus ring effect */
  }

  .register-input {
    padding: 1rem;
    border: 1px solid #dcdbdb;
    border-radius: 4px;
    margin: 0.25rem 0px;
  }
}

input:read-only {
  background-color: #f5f5f5;
}

.read-only-input {
  background-color: #f5f5f5;
}

@layer utilities {
  .primary-shadow {
    --tw-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
    --tw-shadow-colored: 0px 8px 13px -3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

/* Company Scrolling Images */
.companies-image-container {
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
}

.companies-image-row {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.company-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.company-images img {
  padding: 0.25rem 1rem;
  object-fit: cover;
}

@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1024px) {
  .company-images {
    animation: scrollImages 15s linear infinite;
  }
}

/* @media (min-width: 900px) {
  .container {
      max-width: 900px;
  }
} */
/* @media (min-width: 768px) {
  .container {
      max-width: 768px;
  }
} */
/* @media (min-width: 640px) {
  .container {
      max-width: 640px;
  }
}
@media (min-width: 300px) {
  .container {
      max-width: 300px;
  }
} */

.scroll-hidden {
  overflow-y: scroll; /* Allow vertical scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scroll-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
}
/* 
select {
  -webkit-appearance: none;
  appearance: none;
}
.select-wrapper {
  position: relative;
} */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 6px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.select-wrapper::after {
  content: '▼';
  font-size: 0.7rem;
  top: 9px;
  right: 10px;
  position: absolute;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
